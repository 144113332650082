import React from "react";
import {Col, Row} from "react-bootstrap";

import GridImageWrapper from "../common/GridImageWrapper";

const HomePage = () => {
  const links = {
    story: {title: "story", imgUrl: "images/ravenite_story_page_thumbnail.jpg", path: "/story"},
    contact: {title: "contact", imgUrl: "images/ravenite_contact_page_thumbnail.jpg", path: "/contact"},
    collection: {title: "spring 23 <br/> collection", imgUrl: "images/ravenite_lookbook_final_23.jpg", path: "/spring-23-collection"}
  }

  return (

    <>
      <Row md={2} sm={1}>
          <Col xl="4" lg="5" md="6" sm="8" xs="12" className="my-3 ms-md-auto me-md-0 mx-sm-auto col-xxl-3">
              <GridImageWrapper linkObj={links.collection} textHidden={false}/>
          </Col>
        <Col xl="4" lg="5" md="6" sm="8" xs="12" className="my-3 me-md-auto ms-md-0 mx-sm-auto col-xxl-3">
          <GridImageWrapper linkObj={links.story}/>
        </Col>
      </Row>

      <Row md={1}>
        <Col xl="4" lg="5" md="6" sm="8" xs="12" className="mx-auto my-3 col-xxl-3">
          <GridImageWrapper linkObj={links.contact}/>
        </Col>
      </Row>
    </>
  )
}

export default HomePage;