import React from "react";
import SocialButtonsList from "./SocialButtonsList";

const Footer = () => {
  return (
    <footer>
      <div><SocialButtonsList /></div>
      <div className="mt-2"><span className="small">© 2021 - 2023 the-ravenite</span></div>
    </footer>
  );
}

export default Footer;