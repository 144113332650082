import React from "react";
import {Col, Row} from "react-bootstrap";

import GridImageWrapper from "../common/GridImageWrapper";
import {Lightbox} from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const CollectionPage = () => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(0);

  let imgs = [
    {imgUrl: "images/spring-summer-23-collection/1ravenite_lookbook_final_01.jpg", index: 0},
    {imgUrl: "images/spring-summer-23-collection/2ravenite_lookbook_final_04.jpg", index: 1},
    {imgUrl: "images/spring-summer-23-collection/3ravenite_lookbook_final_05.jpg", index: 2},
    {imgUrl: "images/spring-summer-23-collection/4ravenite_lookbook_final_09.jpg", index: 3},
    {imgUrl: "images/spring-summer-23-collection/5ravenite_lookbook_final_20.jpg", index: 4},
    {imgUrl: "images/spring-summer-23-collection/6ravenite_lookbook_final_22.jpg", index: 5},
    {imgUrl: "images/spring-summer-23-collection/7ravenite_lookbook_final_12.jpg", index: 6},
    {imgUrl: "images/spring-summer-23-collection/8ravenite_lookbook_final_10.jpg", index: 7},
    {imgUrl: "images/spring-summer-23-collection/10ravenite_lookbook_final_26.jpg", index: 8},
    {imgUrl: "images/spring-summer-23-collection/11ravenite_lookbook_final_14.jpg", index: 9},
    {imgUrl: "images/spring-summer-23-collection/12ravenite_lookbook_final_07.jpg", index: 10},
    {imgUrl: "images/spring-summer-23-collection/13ravenite_lookbook_final_16.jpg", index: 11},
    {imgUrl: "images/spring-summer-23-collection/14ravenite_lookbook_final_24.jpg", index: 12},
    {imgUrl: "images/spring-summer-23-collection/15ravenite_lookbook_final_30.jpg", index: 13},
    {imgUrl: "images/spring-summer-23-collection/16ravenite_lookbook_final_28.jpg", index: 14}
  ]

  imgs.sort((a, b) => {
    if (a.index > b.index) return 1;
    if (a.index < b.index) return -1
    return 0
  }).forEach(i => {
    i.src = i.imgUrl
    i.width = 2953
    i.height = 4134
    return i
  })

  return (
    <div className="page-content-wrapper collections">
      <h1 className="large-margin-t">Spring 23 Collection</h1>
      <Row>
        <Col sm={10} xs={12} className="offset-sm-1 offset-xs-0">
          <Row>
            {imgs.map((o, ix) => (
              <Col xl="4" lg="6" md="6" sm="12" xs="12" className="my-3" key={"spring-23-collection-" + ix}>
                <GridImageWrapper linkObj={o} clickable={true} oc={() => {
                  setSelected(ix)
                  setOpen(true)
                }}/>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={imgs}
        index={selected}
        carousel={{preload: 4}}
        plugins={[Thumbnails, Zoom]}
      />
    </div>
  )
}

export default CollectionPage;