import React from 'react';

import {Button} from 'react-bootstrap';
import {FaInstagram} from 'react-icons/fa';

function SocialButtonsList() {
  return (
    <div>
      <a className="icon-link" target="_blank" rel="noreferrer" href="https://www.instagram.com/the__ravenite/" title="Ravenite Instagram">
        <Button variant="outline-light" size="sm" className="instagram-button">
          <FaInstagram/>
        </Button>
      </a>
    </div>
  );
}

export default SocialButtonsList;
