import React from 'react';
import {Container} from "react-bootstrap";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Header from "./common/Header";
import Footer from "./common/Footer";

import StoryPage from "./pages/StoryPage";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import CollectionPage from "./pages/CollectionPage";

import 'array-flat-polyfill';

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import './App.scss';

const App = () => {

  return (
    <Router>
      <div className="app-wrapper">
        <Header/>

        <Container fluid className="app-content-wrapper">
          <Switch>
            <Route path="/contact">
              <ContactPage/>
            </Route>
            <Route path="/story">
              <StoryPage/>
            </Route>
            <Route path="/spring-23-collection">
              <CollectionPage/>
            </Route>
            <Route path="/">
              <HomePage/>
            </Route>
          </Switch>
        </Container>

        <Footer/>

      </div>
    </Router>
  );
}

export default App;
