import React from "react";

const ContactPage = () => {
  return (
    <div className="page-content-wrapper text-center">
      <h1 className="large-margin-t">Contact the ravenite</h1>
      <h2 className="large-margin-t">General enquiries</h2>
      <span className="large-text"><a href="mailto:info@the-ravenite.com">info@the-ravenite.com</a></span>

      {/*<h2 className="large-margin-t">Buying enquiries</h2>*/}
      {/*<span className="large-text"><a href="mailto:sales@the-ravenite.com">sales@the-ravenite.com</a></span>*/}
    </div>
  );
}

export default ContactPage;