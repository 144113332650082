import React from "react";

import {useHistory} from 'react-router-dom';

const GridImageWrapper = ({linkObj, clickable=true, textHidden=true, oc=null}) => {
  const history = useHistory();

  let className = "grid-image-wrapper"

  if (clickable) {
    className += " clickable"

    if (textHidden) {
       className += " text-hide"
    }
  }

  const onClick = () => {
    if (linkObj.path) {
      history.push(linkObj.path);
    } else if (oc) {
      oc()
    }
  }

  return (
    <div className={className} style={{"backgroundImage": `url(${linkObj.imgUrl})`, whiteSpace: "pre-line" }} onClick={onClick}>
      {linkObj.title &&
      <div className="text-center"><h1>{linkObj.title.replace("<br/>", "\n")}</h1></div>
      }
    </div>
  )
}

export default GridImageWrapper;