import React from "react";

import {useHistory} from 'react-router-dom';


const Header = () => {
  const history = useHistory();

  const onClick = () => {
    history.push("/");
  }

  return (
    <header className="text-center">
      <img src="images/ravenite_logo.png" width="200" alt="The ravenite logo" onClick={onClick}/>
    </header>
  );
}

export default Header;