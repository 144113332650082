import React from "react";
import {Col, Row} from "react-bootstrap";
import GridImageWrapper from "../common/GridImageWrapper";

const StoryPage = () => {
  return (
    <div className="page-content-wrapper">
      <Row xs={1}>
        <Col md="8" xl="5" lg="6" className="mx-auto fw-bold ">
          <h1 className="large-margin-t">Story</h1>
          <div style={{"textAlign": "justify"}}>
          <p>
            Ravenite is a creative womenswear house created in 2019 by Swiss designer Marc Forster.
            It is mainly inspired by housewives, film noirs and menswear such as tailoring and workwear.
            The identity of Ravenite is not defined by a genre but by an emotion.
            Madness, passion or feeling of betrayal are all highlighted into the collections.
          </p>
          <p>
            Marc Forster re-interpret the luxury aesthetic of Italian tailoring and workwear codes and
            plays with the curves of the female body by offering exaggerated and strict lines garments;
            characterized by powerful but fragile silhouettes.
          </p>
          <p>
            Tomato sauce, immaculate tiles and tailoring. There is no better definition.
          </p>
          </div>
        </Col>
      </Row>
      <Row xs={1}>

        <Col xl="4" lg="5" md="6" sm="8" xs="12" className="mx-auto my-3">
          <GridImageWrapper linkObj={{imgUrl: "images/DEFILE19_CATWALK-BA_HEAD_BaptisteCoulon.jpg"}} clickable={false}/>
        </Col>
      </Row>
    </div>
  )
}

export default StoryPage;